<template>
  <div class="pro_del_bar flex-r-sa-c">
    <div class="left_btn flex-r-sa-c">
      <div class="left_btn_item flex-c-c-c" @click="(e) => { $router.push('/product/classify') }">
        <van-icon name="apps-o" /><span>分类</span>
      </div>
      <van-badge :content="$store.getters.totalShopping" color="#EA5C36">
        <div class="left_btn_item flex-c-c-c" @click="(e) => { $router.push('/cart/cart') }">
          <van-icon name="shopping-cart-o" /><span>购物车</span>
        </div>
      </van-badge>
      <!-- <div class="left_btn_item flex-c-c-c" @click="openKeFu">
        <van-icon name="service-o" /><span>客服</span>
      </div> -->
      <!-- <div v-if="collect === 1" class="left_btn_item flex-c-c-c" @click="doClickFavor(false)">
        <van-icon name="star" color="rgb(255, 181, 35)" /><span>取消</span>
      </div>
      <div v-else class="left_btn_item flex-c-c-c" @click="doClickFavor(true)">
        <van-icon name="star-o" /><span>收藏</span>
      </div> -->
    </div>
    <div v-if="isSeckill" class="right_btn flex-r-s-s">
      <div class="right_btn_seckill" @click="subPro">去抢购</div>
    </div>
    <div v-else-if="newuser" class="right_btn flex-r-s-s">
      <div class="right_btn_seckill" @click="subPro">去抢购</div>
    </div>
    <div v-else class="right_btn flex-r-s-s">
      <div v-if="(onoff === 1 || onoff === 3 )&& from === 1" class="right_btn_cart" @click="addCart">加入购物车</div>
      <div v-if="onoff === 1 || onoff === 3" class="right_btn_pay" @click="subPro">立即采购</div>
      <div v-else class="right_btn_out">商品补货中...</div>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
// import { shoppingCart_addUnion } from '@/api/cart'
import { user_collection, user_collection_delete } from '@/api/pro'
import kefuBtn from '@/components/kefu_btn'
export default {
  components: { kefuBtn },
  // eslint-disable-next-line vue/require-prop-types
  props: ['from', 'info', 'total', 'companyId', 'onoff', 'activty', 'buyNum', 'fashProNum', 'minQuantity', 'collect', 'isSeckill', 'newuser'],
  data() {
    return {
    }
  },
  methods: {
    addCart() {
      this.$emit('addCart')
      // const parms = {
      //   companyId: '1000',
      //   products: [{ productId: this.info, proNumber: this.minQuantity }]
      // }
      // console.log(parms)
      // shoppingCart_addUnion(parms).then(res => {
      //   this.$toast({ message: '加入成功！', duration: 1 * 1000 })
      //   this.$utils.cartNum(this.$store)
      // })
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    subPro() {
      // const token = JSON.parse(localStorage.getItem('USER_INFO')).accessToken
      // if (!token) { this.$router.push('/user/login') }

      // 合并数据
      // 跳转参数
      let url = ''
      // 详情立即购买
      if (this.from === 1 && this.info) {
        if (this.fashProNum === null || this.fashProNum === undefined) {
          url = `/order/subOrder?item=${this.info}-${this.minQuantity}-${this.companyId}&type=2`
        } else {
          url = `/order/subOrder?item=${this.info}-${this.fashProNum}-${this.companyId}&type=2`
        }
      } else if (this.from === 2 && this.info) {
        const ids = []
        for (const item of this.info) {
          ids.push(item.productId + '-' + item.proNumber + '-' + this.companyId)
        }
        url = `/order/subOrder?item=${ids.join(',')}&type=2`
      }
      // 参数不为空
      if (url !== '') {
        this.$router.push(url)
      }
    },
    doClickFavor(status) {
      if (status) {
        user_collection({ productId: this.info }).then(res => {
          this.collect = 1
          this.$toast({ message: status ? '收藏成功！' : '取消成功！', duration: 1 * 1000 })
        })
      } else {
        user_collection_delete(this.info).then(res => {
          this.collect = 0
          this.$toast({ message: status ? '收藏成功！' : '取消成功！', duration: 1 * 1000 })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.pro_del_bar {
  position: fixed;
  width: 10rem;
  height: 1.71rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 0rem;
  bottom: 0;
  .left_btn {
    width: 30%;
    .left_btn_item {
      font-size: 0.52rem;
      color: #686868;
    }
    span {
      font-size: 0.32rem;
      font-weight: 400;
      color: #686868;
    }
  }
  .right_btn {
    font-size: 0.37rem;
    line-height: 1.17rem;
    color: #ffffff;
    .right_btn_cart {
      width: 2.8rem;
      height: 1.17rem;
      background: #0767ab;
      border-top-left-radius: 0.59rem;
      border-bottom-left-radius: 0.59rem;
    }
    .right_btn_pay {
      width: 2.8rem;
      height: 1.17rem;
      background: #d1531c;
      border-top-right-radius: 0.59rem;
      border-bottom-right-radius: 0.59rem;
    }
    .right_btn_out {
      width: 5.6rem;
      height: 1.17rem;
      background: rgb(135, 153, 163);
      border-radius: 0.59rem;
    }
    .right_btn_seckill {
      width: 5.6rem;
      height: 1.17rem;
      background: #d1531c;
      border-radius: 0.59rem;
    }
  }
}
</style>
